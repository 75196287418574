.serechbar {
    outline-width: 1px;
    outline-style: auto;
    outline-color: black;
    width: 90%;
    min-width: 300px;
    justify-self: center;
    align-self: center;
    transition-duration: 150ms;
    transition-property: all;
    margin-left: 1%;
}

.serechbar:hover {
    width: 95%;
    min-width: 530px;
    transition-duration: 150ms;
    transition-property: all;
}


.dl-page {
    align-self: center;
    min-width: 480px;
}

.dl-page:hover{
    
}

.downlitle{
    margin-top: 100px;
}

.blender{
    mix-blend-mode:difference;
    align-self: center;
    width: 50%;
    opacity: 80%;
    transition-duration: 500ms;
    min-width: 425px;
}

.textkid {
    width: 100%;
    align-self: center;
    align-items: center;
    justify-self: center;
    transition-duration: 300ms;
}

.textkid:hover{
    font-size: 55px;
}

.blender:hover {
    opacity: 110%;
    enable-background: 0pc;
    transition-duration: 500ms;
    width: 55%;
    min-width: 450px;
}

.blenderX{
    opacity: 80%;
}

.blenderX:hover{
    opacity: 100%;
}

.animals{
    transition-duration: 400ms;
    min-width: 400px;
    max-width: 500px;
    min-height: 180px;
    max-height: 180px;
   } 

.animals:hover{
    transform: scale(1.05, 1.05);
    transition-duration: 400ms;
}

.donate{
    min-width: 169px;
    transition-duration: 300ms;
    width: 5%;
}


.donate:hover {
    min-width: 210px;
    transition-duration: 300ms;
    width: 5.25%;
}

.scale-105 {
    backface-visibility: hidden;
    transform: translateZ(0);
}


#searchbar{}

li {
    list-style: none;
}


.cords {

}


.home {
    cursor: none;
  }



.hide {
    display: none;
    transition-duration: 1500ms;
    transition-property: all;
}

.cardlol {
    transition-duration: 1500ms;
    transition-property: all;
}



.cursor {
    background-color: #DEDEDE;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    mix-blend-mode: difference;
  }

.testcolor {
    color: #DEDEDE;
    width: 70px;
    transition-duration: 500ms;
    filter: invert(0.85);
    
}


.testcolor:hover {
    width: 100px;
    transition-duration: 500ms;
    filter: invert(0.9);
}

.paragf {
    text-align: center;
    font-weight: 559;
    margin-right: 2.5%;
    margin-left: 2.5%;
    width: 95%;
}

.hesaidthat {
    text-align: right;
    font-weight: 550;
    margin-right: 3%;
    margin-left: 80%;
    justify-self: right;

}

.downarrow {
    max-width: 55px;
    width: 55px;
    min-width: 55px;
    filter: invert(1);
    align-self: center;
    justify-self: center;
}

.downArrow {
    justify-self: center;
    align-self: center;
    position: fixed;
    bottom: 6%;
    top: 94%
    
}

.bgcolor {
    background: black;
}