.pfp {
    width: 250px;
    height: 250px;

    min-width: 250px;
    max-width: 250px;
    transition-duration: 1000ms;

}

.pfp:hover {

    filter:brightness(1.11);
    transition-duration: 1000ms;

}


.name {
    max-width: 100%;
    text-align: center;
}
.aboutme {
    font-size: x-large;
    max-width: 100%;
    justify-self: center;
}

.npfpab {
    justify-content: center;
    max-width: 1000px;
    max-height:1000px;
    align-items: center;
    min-width: 400px;

}

.medialist{
    min-height: 100px;

}